<template>
  <!-- 题单答题记录 -->
  <div class="tiDanRecord">
    <div class="content">
      <Breadcrumb></Breadcrumb>
      <div v-loading="loading" class="box">
        <div class="content-left">
          <div class="course-title">
            <div style="width: 80%">
              <div class="name sameStyle" v-html="detailList.title"></div>
              <div
                style="margin-bottom: 10px; font-size: 14px"
                v-if="
                  detailList.course_list_message &&
                  Object.keys(detailList.course_list_message).length > 0
                "
              >
                <span
                  style="margin-right: 1.5rem"
                  v-if="detailList.course_list_message.course_title"
                  >所属课程:{{
                    detailList.course_list_message.course_title
                  }}</span
                >
                <span v-if="detailList.course_list_message.unit_title"
                  >所属章节:{{
                    detailList.course_list_message.unit_title
                  }}</span
                >
              </div>
              <div class="detailed">
                <span>题目提供者：{{ detailList.set_user_nickname }}</span>
                <span v-if="detailList.diff_title"
                  >难度：{{ detailList.diff_title }}</span
                >
                <!-- <span>提交：{{detailList.log_num}}</span> -->
                <span>通过：{{ detailList.jige_total }}</span>
                <!-- <span v-if="examType=='比赛'">比赛时间：{{detailList.start_time_f}}</span> -->
              </div>
            </div>
            <div class="edit">
              <div
                class="feedback"
                @click="handleCommand('feedback', detailList.title, '答题记录')"
              >
                <i class="el-icon-edit-outline"></i>反馈
              </div>
              <!-- <div class="bgGreen_btn operation" @click="submit">{{headrBtn}}</div> -->
              <!-- <div class="button-primary" :style="{ marginLeft:'5px',padding:'4px 20px'}" @click="$handleRoute({ course_id: $route.query.course_id },'courseDetail')" v-if="($route.query.course_id || 0)">返回课程</div> -->
              <!-- <div class="button-primary" :style="{ marginLeft:'5px',padding:'4px 20px'}" @click="$router.go(-2)" v-if="($route.query.course_id || 0)">返回课程</div> -->
            </div>
          </div>
          <!-- 题目列表 -->
          <div class="topic_list">
            <div v-for="(item, index) in detailList.log_list" :key="index">
              <div class="topicType">{{ item.title }}</div>
              <!-- 循环题目 -->
              <div v-for="(j, k) in item._list" :key="k" v-if="j.type">
                <template v-if="j.type + '' === '2'">
                  <!-- 判断题 -->

                  <PDT
                    :resultShow="resultShow"
                    :optionsData="{
                      isAnswer: true,
                      topicData: { ...j, myAnswer: j.my_answer, log_id },
                    }"
                    v-model="j.my_answer"
                    :alone="true"
                    :discuss-show="true"
                    v-if="j.is_select + '' === '2'"
                  ></PDT>
                  <!-- 单选题 -->
                  <DXT
                    :optionsData="{
                      isAnswer: true,
                      topicData: { ...j, myAnswer: j.my_answer, log_id },
                    }"
                    v-model="j.my_answer"
                    :alone="true"
                    :discuss-show="true"
                    :resultShow="resultShow"
                    v-else-if="j.is_select + '' === '1'"
                  ></DXT>
                  <!-- 多选题 -->
                  <DUOXT
                    :optionsData="{
                      isAnswer: true,
                      topicData: { ...j, myAnswer: j.my_answer, log_id },
                    }"
                    v-model="j.my_answer"
                    :alone="true"
                    :discuss-show="true"
                    :resultShow="resultShow"
                    v-else-if="j.is_select + '' === '3'"
                  ></DUOXT>
                  <!-- 填空题 -->
                  <TKT
                    :optionsData="{
                      isAnswer: true,
                      topicData: { ...j, myAnswer: j.my_answer, log_id },
                    }"
                    v-model="j.my_answer"
                    :alone="true"
                    :discuss-show="true"
                    :resultShow="resultShow"
                    v-else
                  ></TKT>
                </template>
                <!-- 综合题 -->
                <template v-if="j.type + '' === '3'">
                  <div
                    class="synthesize"
                    style="display: flex; margin-left: 20px"
                  >
                    <span v-html="j.title"></span
                    ><span>（{{ j.grade }}分）</span>
                  </div>
                  <div
                    style="margin-bottom: 1rem; margin-left: 20px"
                    v-code-html="j.description"
                  ></div>
                  <div v-for="(q, w) in j._list" :key="w">
                    <!-- 判断题 -->
                    <PDT
                      :optionsData="{
                        isAnswer: true,
                        topicData: { ...q, myAnswer: q.my_answer, log_id },
                      }"
                      v-model="q.my_answer"
                      :alone="true"
                      :resultShow="resultShow"
                      v-if="q.is_select + '' === '2'"
                    ></PDT>
                    <!-- 单选题 -->
                    <DXT
                      :optionsData="{
                        isAnswer: true,
                        topicData: { ...q, myAnswer: q.my_answer, log_id },
                      }"
                      v-model="q.my_answer"
                      :alone="true"
                      :resultShow="resultShow"
                      v-else-if="q.is_select + '' === '1'"
                    ></DXT>
                    <!-- 多选题 -->
                    <DUOXT
                      :optionsData="{
                        isAnswer: true,
                        topicData: { ...q, myAnswer: q.my_answer, log_id },
                      }"
                      v-model="q.my_answer"
                      :alone="true"
                      :resultShow="resultShow"
                      v-else-if="q.is_select + '' === '3'"
                    ></DUOXT>
                    <!-- 填空题 -->
                    <TKT
                      :optionsData="{
                        isAnswer: true,
                        topicData: { ...q, myAnswer: q.my_answer, log_id },
                      }"
                      v-model="q.my_answer"
                      :alone="true"
                      :resultShow="resultShow"
                      v-else
                    ></TKT>
                  </div>
                </template>
                <!-- 编程题 -->
                <template v-if="j.type + '' === '1'">
                  <BCT
                    ref="BCT"
                    :optionsData="{
                      isAnswer: true,
                      topicData: { ...j, myAnswer: j.my_answer + '', log_id },
                    }"
                    @update="
                      ({ topicData }) => {
                        detailList.log_list[index] = topicData;
                      }
                    "
                    @wait="topicLWait"
                    @updateData="updateData"
                    :discuss-show="true"
                    :alone="true"
                    :resultShow="resultShow"
                  ></BCT>
                  <!-- <BCT ref="BCT" :optionsData="{isAnswer:true,topicData:{...j,myAnswer:j.my_answer+'',log_id}}" 
                    @update="updateBct(index)" 
                    @wait="topicLWait"
                    :discuss-show="true" :alone="true"></BCT> -->
                </template>
                <el-divider></el-divider>
              </div>
            </div>
          </div>
        </div>
        <div class="content-right">
          <div class="fixed">
            <div class="contDown">
              <div class="sameStyle">得分：</div>
              <div class="time" v-if="waitNums.length > 0">请等待</div>

              <div
                class="time"
                style="font-size: 32px"
                v-else-if="typeof detailList.grade === 'number'"
              >
                {{
                  detailList.my_grade
                    ? detailList.my_grade
                    : detailList.grade || 0
                }}分/{{ Number(detailList.total_grade).toFixed(0) }}分
              </div>
              <div class="time" v-else>待通知</div>
            </div>
            <!-- <div class="recently_record">
              <div class="record_title">
                <div class="sameStyle">最近提交记录</div>
                <!== <div class="title_btn" @click="record_list_show=!record_list_show">
                  {{ record_list_show ? '收起' : '展开' }}
                </div> ==>
              </div>
              <!== <div v-show="record_list_show" class="record_list"> ==>
              <div class="record_list">
                <!== <div v-for="(item,index) in log_list" :key="index" class="record_list_item"
                     @click="log_list_click(item)"> ==>
                <div v-for="(item,index) in log_list" :key="index" class="record_list_item">
                  <span>{{ item.grade }}分—</span><span>提交于：{{ item.last_commit_date }}</span>
                </div>
              </div>
            </div> -->
            <div style="width: 100%">
              <TopicList
                ref="TopicList"
                :pitchNums="pitchNums"
                :waitNums="waitNums"
                :topicParam="topicParam"
                @topicClick="topicClick"
                v-if="pitchNums.length > 0"
              ></TopicList>

              <!-- <HotRecommend :title="examType == '比赛'?'热门推荐':'推荐题目'" class="recommend" :type="examType == '比赛'?'questList':'question'"></HotRecommend> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-backtop :bottom="100" :right="400" :visibility-height="400">
      <div class="go-top">
        <i class="el-icon-caret-top" style="color: #00957e"></i>
        <div style="margin-top: -8px">顶部</div>
      </div>
    </el-backtop>
  </div>
</template>

<script>
// 题目列表和知识点标签
// import TopicList from "@/components/TopicList/TopicList_new.vue"
import TopicList from "@/components/TopicList/TopicList.vue";

import { answer_result, get_log_list } from "@/api/topic.js";
import { bisaidetail } from "@/api/problemList.js";
import TopicOperate from "@/components/TopicOperate/TopicOperate.vue";
import PDT from "@/components/TopicType/PDT";
import DXT from "@/components/TopicType/DXT";
import DUOXT from "@/components/TopicType/DUOXT";
import TKT from "@/components/TopicType/TKT";
import BCT from "@/components/TopicType/BCT";
export default {
  components: {
    BCT,
    TKT,
    DUOXT,
    DXT,
    PDT,
    TopicList,
    TopicOperate,
  },
  data() {
    return {
      record_list_show: false,
      loading: false,
      detailList: {},
      waitNums: [],
      id: "",
      examType: "",
      examTypes: ["比赛", "作业", "考试"],
      log_id: "",
      topicParam: {
        topicList: [],
        knowledgeTag: [],
      },
      pitchNums: [],
      log_list: [],
      style: "",
      resultShow: false,
    };
  },
  watch: {
    $route() {
      this.log_id = this.$route.query.log_id;
      if (this.examTypes.indexOf(this.examType) > -1) {
        this.get_bisaidetail();
      } else {
        this.getDetailList();
      }
    },
  },
  created() {
    let { id, type, log_id, style } = this.$route.query;
    this.id = id;
    this.examType = type;
    this.style = style;
    console.log(this.style, " tstylestyle");
    // if (this.style == "考试") {
    //   this.resultShow = false;
    // } else {
    //   this.resultShow = true;
    // }
    this.log_id = log_id;
    console.log(this.log_id, " this.log_id this.log_id");
    if (this.examTypes.indexOf(this.examType) > -1) {
      this.get_bisaidetail();
    } else {
      this.getDetailList();
    }
  },
  mounted() {
    // if(location.href.indexOf("#reloaded")==-1){
    //     location.href=location.href+"#reloaded";
    //     location.reload();
    // }
  },
  methods: {
    log_list_click(item) {
      this.$router.replace({
        path: this.$route.path,
        query: { log_id: item.id },
      });
    },
    //获取提交记录
    record_get_log_list() {
      get_log_list({
        page_size: 5,
        page: 1,
        other_id: (this.detailList || {}).other_id || 0,
        user_id: this.userInfo.id,
        type: this.examTypes.indexOf(this.examType) > -1 ? "other" : "list",
      }).then((res) => {
        this.log_list = (res.data || {}).data || [];
      });
    },
    //右侧题目列表点击
    //滚动到点击的题目
    topicClick(selector) {
      document.getElementById(selector).scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
    updateData() {
      this.getDetailList();
    },
    topicLWait({ id, status }) {
      if (status === 1) {
        this.waitNums.splice(this.waitNums.indexOf(id), 1);
        console.log(this.waitNums, "this.waitNums111");
      } else if (this.waitNums.indexOf(id) === -1) {
        this.waitNums.push(id);
        console.log(this.waitNums, "this.waitNums222");
      }
    },
    //获取题单详情
    getDetailList() {
      this.loading = true;

      answer_result({ id: this.log_id })
        .then((res) => {
          var detailList = res.data.info;
          if (typeof detailList.grade === "number") {
            this.resultShow = true;
          } else {
            this.resultShow = false;
          }
          detailList.log_list = res.data.log_list;

          //设置右边题目列表
          this.topicParam = {
            topicList: res.data.title_list,
            knowledgeTag: res.data.info.knows,
          };
          this.detailList = detailList;
          // console.log(this.detailList,"this.detailListthis.detailList")
          // console.log(this.detailList.log_list,"111111111111111")
          var pitchNums = [];
          res.data.log_list.map((v) => {
            if (v.type == 3 && v.is_select == 0) {
              v._list.map((j) => {
                pitchNums.push(...j._list);
              });
            } else {
              pitchNums.push(...v._list);
            }
          });
          this.pitchNums = pitchNums;
          this.record_get_log_list();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //获取比赛，考试的详情
    get_bisaidetail() {
      bisaidetail({ id: this.id })
        .then((res) => {
          console.log(res.data, "比赛");
          let info = res.data.info;
          this.timeInfos[0] = info.duration;
          this.detailList = {
            diff_title: info.diff_title,
            user_real_name: info.user_name,
            title: info.title,
            start_time_f: info.start_time_f,
            log_num: info.count,
            jige_total: info.jige,
            questions_list: res.data.questions,
          };
          this.questionsNum = res.data.questions.length;
          this.topicParam = {
            topicList: [...info.questions],
            knowledgeTag: info.knows,
          };
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/css/topic.less";

.tiDanRecord {
  min-height: calc(100vh - 14.25rem);

  .go-top {
    .el-icon-caret-top {
      font-size: 26px;
      margin-top: -6px;
    }

    text-align: center;
    font-size: 14px;
    font-weight: 600;
  }

  .box {
    display: flex;
    justify-content: space-between;

    .content-left {
      width: 70%;

      .course-title {
        padding: 2rem;
        background: #ffffff;
        border-radius: 10px;
        border: 1px solid #dbdbdb;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .edit {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .feedback {
            display: flex;
            align-items: center;
            font-size: 14px;
            cursor: pointer;
          }

          .el-icon-edit-outline {
            font-size: 20px;
            margin-right: 0.2rem;
          }
        }

        .name {
          font-size: 21px;
          color: #0a0a0a;
          line-height: 29px;
          margin-bottom: 1rem;
        }

        .detailed {
          font-size: 14px;
          color: #0a0a0a;
          line-height: 20px;

          > span {
            margin-right: 1.5rem;
          }
        }
      }
    }

    .content-right {
      width: 27%;

      .fixed {
        // position: fixed;
        width: 350px;
        height: 100vh;
        overflow-y: auto;
        background-color: #f5f5f5;
      }

      .recently_record {
        // width: 350px;
        box-sizing: border-box;
        background-color: #fff;
        border: 1px solid #dbdbdb;
        padding: 1rem;
        margin-bottom: 40px;

        .record_list {
          height: 200px;
          overflow: hidden;
          overflow-y: auto;
        }
        .record_list_item {
          margin-top: 0.8rem;
          // cursor: pointer;
        }

        .record_title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .title_btn {
            color: #00957e;
            cursor: pointer;
          }
        }
      }

      .contDown {
        font-size: 18px;
        font-weight: 600;
        color: #303030;
        // position: fixed;
        // width: 345px;
        // margin-bottom: 40px;
        .time {
          text-align: center;
          font-size: 52px;
          font-weight: 600;
          margin: 2rem;
        }
      }
    }
  }
}
.sameStyle {
  color: #00957e !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 38px !important;
}
</style>