var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tiDanRecord"},[_c('div',{staticClass:"content"},[_c('Breadcrumb'),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"box"},[_c('div',{staticClass:"content-left"},[_c('div',{staticClass:"course-title"},[_c('div',{staticStyle:{"width":"80%"}},[_c('div',{staticClass:"name sameStyle",domProps:{"innerHTML":_vm._s(_vm.detailList.title)}}),(
                _vm.detailList.course_list_message &&
                Object.keys(_vm.detailList.course_list_message).length > 0
              )?_c('div',{staticStyle:{"margin-bottom":"10px","font-size":"14px"}},[(_vm.detailList.course_list_message.course_title)?_c('span',{staticStyle:{"margin-right":"1.5rem"}},[_vm._v("所属课程:"+_vm._s(_vm.detailList.course_list_message.course_title))]):_vm._e(),(_vm.detailList.course_list_message.unit_title)?_c('span',[_vm._v("所属章节:"+_vm._s(_vm.detailList.course_list_message.unit_title))]):_vm._e()]):_vm._e(),_c('div',{staticClass:"detailed"},[_c('span',[_vm._v("题目提供者："+_vm._s(_vm.detailList.set_user_nickname))]),(_vm.detailList.diff_title)?_c('span',[_vm._v("难度："+_vm._s(_vm.detailList.diff_title))]):_vm._e(),_c('span',[_vm._v("通过："+_vm._s(_vm.detailList.jige_total))])])]),_c('div',{staticClass:"edit"},[_c('div',{staticClass:"feedback",on:{"click":function($event){return _vm.handleCommand('feedback', _vm.detailList.title, '答题记录')}}},[_c('i',{staticClass:"el-icon-edit-outline"}),_vm._v("反馈 ")])])]),_c('div',{staticClass:"topic_list"},_vm._l((_vm.detailList.log_list),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"topicType"},[_vm._v(_vm._s(item.title))]),_vm._l((item._list),function(j,k){return (j.type)?_c('div',{key:k},[(j.type + '' === '2')?[(j.is_select + '' === '2')?_c('PDT',{attrs:{"resultShow":_vm.resultShow,"optionsData":{
                    isAnswer: true,
                    topicData: { ...j, myAnswer: j.my_answer, log_id: _vm.log_id },
                  },"alone":true,"discuss-show":true},model:{value:(j.my_answer),callback:function ($$v) {_vm.$set(j, "my_answer", $$v)},expression:"j.my_answer"}}):(j.is_select + '' === '1')?_c('DXT',{attrs:{"optionsData":{
                    isAnswer: true,
                    topicData: { ...j, myAnswer: j.my_answer, log_id: _vm.log_id },
                  },"alone":true,"discuss-show":true,"resultShow":_vm.resultShow},model:{value:(j.my_answer),callback:function ($$v) {_vm.$set(j, "my_answer", $$v)},expression:"j.my_answer"}}):(j.is_select + '' === '3')?_c('DUOXT',{attrs:{"optionsData":{
                    isAnswer: true,
                    topicData: { ...j, myAnswer: j.my_answer, log_id: _vm.log_id },
                  },"alone":true,"discuss-show":true,"resultShow":_vm.resultShow},model:{value:(j.my_answer),callback:function ($$v) {_vm.$set(j, "my_answer", $$v)},expression:"j.my_answer"}}):_c('TKT',{attrs:{"optionsData":{
                    isAnswer: true,
                    topicData: { ...j, myAnswer: j.my_answer, log_id: _vm.log_id },
                  },"alone":true,"discuss-show":true,"resultShow":_vm.resultShow},model:{value:(j.my_answer),callback:function ($$v) {_vm.$set(j, "my_answer", $$v)},expression:"j.my_answer"}})]:_vm._e(),(j.type + '' === '3')?[_c('div',{staticClass:"synthesize",staticStyle:{"display":"flex","margin-left":"20px"}},[_c('span',{domProps:{"innerHTML":_vm._s(j.title)}}),_c('span',[_vm._v("（"+_vm._s(j.grade)+"分）")])]),_c('div',{directives:[{name:"code-html",rawName:"v-code-html",value:(j.description),expression:"j.description"}],staticStyle:{"margin-bottom":"1rem","margin-left":"20px"}}),_vm._l((j._list),function(q,w){return _c('div',{key:w},[(q.is_select + '' === '2')?_c('PDT',{attrs:{"optionsData":{
                      isAnswer: true,
                      topicData: { ...q, myAnswer: q.my_answer, log_id: _vm.log_id },
                    },"alone":true,"resultShow":_vm.resultShow},model:{value:(q.my_answer),callback:function ($$v) {_vm.$set(q, "my_answer", $$v)},expression:"q.my_answer"}}):(q.is_select + '' === '1')?_c('DXT',{attrs:{"optionsData":{
                      isAnswer: true,
                      topicData: { ...q, myAnswer: q.my_answer, log_id: _vm.log_id },
                    },"alone":true,"resultShow":_vm.resultShow},model:{value:(q.my_answer),callback:function ($$v) {_vm.$set(q, "my_answer", $$v)},expression:"q.my_answer"}}):(q.is_select + '' === '3')?_c('DUOXT',{attrs:{"optionsData":{
                      isAnswer: true,
                      topicData: { ...q, myAnswer: q.my_answer, log_id: _vm.log_id },
                    },"alone":true,"resultShow":_vm.resultShow},model:{value:(q.my_answer),callback:function ($$v) {_vm.$set(q, "my_answer", $$v)},expression:"q.my_answer"}}):_c('TKT',{attrs:{"optionsData":{
                      isAnswer: true,
                      topicData: { ...q, myAnswer: q.my_answer, log_id: _vm.log_id },
                    },"alone":true,"resultShow":_vm.resultShow},model:{value:(q.my_answer),callback:function ($$v) {_vm.$set(q, "my_answer", $$v)},expression:"q.my_answer"}})],1)})]:_vm._e(),(j.type + '' === '1')?[_c('BCT',{ref:"BCT",refInFor:true,attrs:{"optionsData":{
                    isAnswer: true,
                    topicData: { ...j, myAnswer: j.my_answer + '', log_id: _vm.log_id },
                  },"discuss-show":true,"alone":true,"resultShow":_vm.resultShow},on:{"update":({ topicData }) => {
                      _vm.detailList.log_list[index] = topicData;
                    },"wait":_vm.topicLWait,"updateData":_vm.updateData}})]:_vm._e(),_c('el-divider')],2):_vm._e()})],2)}),0)]),_c('div',{staticClass:"content-right"},[_c('div',{staticClass:"fixed"},[_c('div',{staticClass:"contDown"},[_c('div',{staticClass:"sameStyle"},[_vm._v("得分：")]),(_vm.waitNums.length > 0)?_c('div',{staticClass:"time"},[_vm._v("请等待")]):(typeof _vm.detailList.grade === 'number')?_c('div',{staticClass:"time",staticStyle:{"font-size":"32px"}},[_vm._v(" "+_vm._s(_vm.detailList.my_grade ? _vm.detailList.my_grade : _vm.detailList.grade || 0)+"分/"+_vm._s(Number(_vm.detailList.total_grade).toFixed(0))+"分 ")]):_c('div',{staticClass:"time"},[_vm._v("待通知")])]),_c('div',{staticStyle:{"width":"100%"}},[(_vm.pitchNums.length > 0)?_c('TopicList',{ref:"TopicList",attrs:{"pitchNums":_vm.pitchNums,"waitNums":_vm.waitNums,"topicParam":_vm.topicParam},on:{"topicClick":_vm.topicClick}}):_vm._e()],1)])])])],1),_c('el-backtop',{attrs:{"bottom":100,"right":400,"visibility-height":400}},[_c('div',{staticClass:"go-top"},[_c('i',{staticClass:"el-icon-caret-top",staticStyle:{"color":"#00957e"}}),_c('div',{staticStyle:{"margin-top":"-8px"}},[_vm._v("顶部")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }